export const environment = {
  production: false,
  map_box_token: '',
  firebase: {
    apiKey: 'AIzaSyCHScUxE_EcjU2tedhivQv-2rtAX5WYVU4',
    authDomain: 'tv-nxt.firebaseapp.com',
    projectId: 'tv-nxt',
    storageBucket: 'tv-nxt.appspot.com',
    messagingSenderId: '921380282098',
    appId: '1:921380282098:web:a0157e41b05f42e4be3a6b',
    measurementId: 'G-FHSSX932ZN',
    vapidKey: '',
  },
  adminEmail: 'admin@tvnxt.com',
  stripe: {
    sk: '',
  },
};
